import _typeof2 from "./typeof.js";
import _assertThisInitialized from "./assertThisInitialized.js";
var exports = {};
var _typeof = _typeof2["default"];
var assertThisInitialized = _assertThisInitialized;
function _possibleConstructorReturn(t, e) {
  if (e && ("object" == _typeof(e) || "function" == typeof e)) return e;
  if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined");
  return assertThisInitialized(t);
}
exports = _possibleConstructorReturn, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;